// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  heartBeat: {
    //url: "http://localhost/api/",
    url: "https://demo.insite.supplierportal.applexus.com/api/",
    timeout: 30000
  },
  sr: {
    // url: "http://localhost:9000/api/",
    // cross_url: "http://localhost:9000/api/",
    url: "https://demo.insite.supplierportal.applexus.com/api/",
    cross_url: "https://demo.insite.supplierportal.applexus.com/api/",
  },
  //supplierAPIUrl:'http://localhost:9000/api/pos',
  supplierAPIUrl:'https://demo.insite.supplierportal.applexus.com/api/pos',


      // SD1
  // client_api:"64625492ba494631fcebd1c60dd49937",
  // x_auth:"12db91b6b3a94f89c93ded12d8386eb4",
  // aider_url:"https://aider.applexus.com/api/v1"

     // FM1
  client_api:"24437559c79d12a611a4b0bdb5fb918f",
  x_auth:"a8cb9f4a3e9245948030303ae07ec46c",
  aider_url:"https://qa.aider.applexus.com/api/v1"

};



/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
